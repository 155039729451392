<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/daily-working`" title="Daily Working" :columns="columns"
                routerpath="/hr/adddailyworking" :formOptions="formOptions" :newPage="true" :edit="edit" :add="add"
                :viewFields="viewFields"
                :canDelete="canDelete" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields: [
        {
          label: 'Date',
          field: 'date',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'employee',
          field: 'employee',
          type: 'dropdown',
          responseValue:'fullname',
          class: "col-md-4",
        },
        {
          label: 'remarks',
          field: 'remarks',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'ducuments',
          field: 'details',
          tableHead: ['From','To','Hours','Work','Description'],
          tableField: [
            {
              type: 'text',
              field: 'from',
            },
            {
              type: 'text',
              field: 'to',
            },
            {
              type: 'text',
              field: 'hours',
            },
            {
              type: 'text',
              field: 'work',
            },
            {
              type: 'text',
              field: 'description',
            }

          ],
          type: 'multiple',
          class: "col-md-12",
        },
        {
          label: 'Total hours',
          field: 'total_hours',
          type: 'text',
          class: "col-md-4",
        },

      ],
      columns: [
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Employee",
          field: "user_id",
          type: 'staticdropdown',
          searchFiled:'user_id',
          url: 'getUsers',
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Total Hours",
          field: "total_hours",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search remarks",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type:'staticdropdown',
          url: 'getUsers',
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: 'staticdropdown',
          url: 'getUsers',
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.query.id ? "Edit Daily Working" : "Add Daily Working"}`,
        submitRouterPath: "/hr/dailyworking",
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/daily-working`,
        getEditValue: `${baseApi}/get-Daily-Working-By-Id`,
        inputFields: [],
      },
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Daily Working") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {

            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
